import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import { Link } from "gatsby"
import Particle11 from "../components/Particle11"
import SEO from "../components/SEO"
import "../css/dsa.css"

const dsa = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="DSA (Basics)"
          description="Projects of Data Structures and Algorithm (Bascis) with Python."
        />
        <Particle11></Particle11>
        <h6>s</h6>
        <div className="programming-project">
          <h1>Data Structures And Algorithms (Basics) With Python</h1>
        </div>

        <div className="attend noSelect">
          <h4>
            <mark className="attention">ATTENTION:</mark> You are looking at one
            of the essential, foremost, significant, imperative, portentous,
            noble, paramount, crucial, pivotal, salient, valuable, prominent,
            prestigious, relevant, preeminent, well-known, esteemed, vital,
            powerful, outstanding, substantial, remarkable, noteworthy, honored
            and important topic.
            <br />
            "The DSA", one of the biggest name in CS/IT field.
          </h4>
        </div>

        <section className="dsa-d noSelect">
          <Link to="/stack/">
            <div type="button" className="dsa">
              <span>Stack</span>
            </div>
          </Link>
          <br />
          <Link to="/queue/">
            <div type="button" className="dsa">
              <span>Queue</span>
            </div>
          </Link>
          <br />
          <Link to="/linkedlist/">
            <div type="button" className="dsa">
              <span>Linked List</span>
            </div>
          </Link>

          <br />
          <Link to="/searching/">
            <div type="button" className="dsa">
              <span>Searching</span>
            </div>
          </Link>
          <br />
          <Link to="/sorting/">
            <div type="button" className="dsa">
              <span>Sorting</span>
            </div>
          </Link>
          <br />
          <Link to="/tree/">
            <div type="button" className="dsa">
              <span>Tree</span>
            </div>
          </Link>
          <br />
          <Link to="/graph/">
            <div type="button" className="dsa">
              <span>Graph</span>
            </div>
          </Link>
        </section>
      </Layout>
    </div>
  )
}

export default dsa
